import React, { useContext } from "react";
import { ProjectContext } from "../../ProjectContext";
import { Link } from "react-router-dom";

export default function Hero() {
  const { bgColor, setBgColor } = useContext(ProjectContext);
  const handleClick = () => {
    setBgColor(bgColor === "white" ? "lightblue" : "white"); // Toggle background color
  };

  return (
    <div>
      <div className="faq-wrapper pb-75 pt-75">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <article className="post-article w-100 d-flex flex-column mt-3">
                <figure className="w-100">
                  <Link to="/base">
                    <img
                      src="assets/images/icon/bnb_bridge.png"
                      alt="blog-post"
                      className="w-100 rounded-25 ovh img-hover"
                    />
                  </Link>
                </figure>
              </article>
            </div>

            <div className="col-lg-3 col-md-6 opacity-25">
              <article className="post-article w-100 d-flex flex-column mt-3">
                <figure className="w-100">
                  <a>
                    <img
                      src="assets/images/icon/polygon.png"
                      alt="blog-post"
                      className="w-100 rounded-25 ovh img-hover"
                    />
                  </a>
                </figure>
              </article>
            </div>
            <div className="col-lg-3 col-md-6 opacity-25">
              <article className="post-article w-100 d-flex flex-column mt-3">
                <figure className="w-100">
                  <a>
                    <img
                      src="assets/images/icon/op_mainnet.png"
                      alt="blog-post"
                      className="w-100 rounded-25 ovh img-hover"
                    />
                  </a>
                </figure>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
