import React, { useContext, useEffect, useState } from "react";
import WithdrawAdresssModal from "../Coman/WithdrawAdresssModal";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";
import SelectTokenModal from "../Coman/SelectTokenModal";


export default function BaseTakan({ rload, setrload }) {
  const { account, connectMetamask, balance, ethers, tokenhex, tokencontract, stakcontract, stakcontractaddress, switchNetwork, settings, isBsc, token_chain2, token_bsc, settoken_chain2, settoken_bsc, toastSuccess, toastError, formatAddress, isOpen, setIsOpen, isOpenToken, setIsOpenToken, selectToken, setselectToken, image } = useContext(ProjectContext)

  const [trBNB, settrBNB] = useState(true);
  const [amount, setamount] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");

  const connect = async () => {


    if (!account) {
      connectMetamask()
      return
    }
  }
  const changeChain = async () => {
    if (!account) {
      connectMetamask()
      return
    }
    await switchNetwork(!trBNB ? 56 : 2889)
    settrBNB(!trBNB)
  }
  // console.log("stakcontractaddress stakcontract", stakcontractaddress, stakcontract);

  const swap = async () => {
    // console.log("final------------", token_bsc[2], token_chain2[2]);

    sethash(null);
    if (!account) {
      toastError("Connect first")
      connectMetamask()
      return
    }
    if (!Number(amount)) {
      toastError("Enter Amount");
      settrdone(false);
      return;
    }
    if (Number(amount) > Number(balance)) {
      toastError("Insufficient Balance");
      settrdone(false);
      return;
    }
    settrdone(true)
    const allowance = await tokencontract.allowance(
      account,
      stakcontractaddress
    );
    // console.log("allowance", ethers.utils.formatEther(allowance), amount, stakcontractaddress);

    async function main() {
      // console.log('amount, tokenhex', amount, tokenhex);

      try {
        var stk = null
        stk = await stakcontract.commit(ethers.utils.parseEther(`${amount}`), tokenhex);

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        toastSuccess("Transaction done");

        console.log("data------------------from", !isBsc ? token_bsc[2] : token_chain2[2]);
        console.log("data------------------to", isBsc ? token_bsc[2] : token_chain2[2]);

        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            hash: receipt.transactionHash,
            type: trBNB ? 0 : 1,
            from: !isBsc ? token_bsc[2] : token_chain2[2],
            to: isBsc ? token_bsc[2] : token_chain2[2],
          })



          .then((res) => {
            if (res.data.error) {
              return "";
            }
          });
        setrload(!rload);
        settrdone(false);
        settoken_bsc("");
        settoken_chain2("");

      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }

    if (Number(ethers.utils.formatEther(allowance)) < Number(amount)) {
      try {
        var approv = await tokencontract.approve(
          stakcontractaddress,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
    // main();

  }

  useEffect(() => {
    settrBNB(isBsc)
  }, [isBsc])

  return (
    <div>
      <div className="contact-wrapper pb-100 pt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
              <form
                action="#"
                className="rounded-25 px-3 mx-md-2 mx-lg-4F mx-0 py-3 light-bg bg-gray-100 contact-form style1 swap-css"
              >
                <div className="row">
                  <div className="text-center mb-2">
                    <h4 className=" text-gray-900 text-center dark-text rajdhani-600 lh-1 mt-0 mb-0">
                      Bridge
                    </h4>
                  </div>
                </div>

                <div className="d-flex flex-row border-exlight border justify-content-between align-items-center border-2 p-3 rounded-25 ">
                  {trBNB === true ? (
                    <div className="d-flex flex-row align-items-center my-auto">
                      <span>
                        <img
                          src="assets/images/icon/bscchain.svg"
                          height="30px"
                          width="30px"
                          className="rounded-pill"
                          alt=""
                        />
                      </span>
                      <div className="ms-2 text-grey-500 text-start">
                        <h5 className="text-gray-900 dark-text font-xss rajdhani-600 lh-18 text-start m-0">
                          From
                        </h5>
                        <h6 className="text-gray-900 lh-18 mb-0 dark-text ">BSC</h6>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-row align-items-center my-auto">
                      <span>
                        <img
                          src="assets/images/icon/aarma.svg"
                          height="30px"
                          width="30px"
                          className="rounded-pill"
                          alt=""
                        />
                      </span>
                      <div className="ms-2 text-grey-500 text-start">
                        <h5 className="text-gray-900 dark-text font-xss rajdhani-600 lh-18 text-start m-0">
                          From
                        </h5>
                        <h6 className="text-gray-900 lh-18 mb-0 dark-text">AARMA</h6>
                      </div>
                    </div>
                  )}

                  <div className="d-flex " style={{ position: "absolute", left: "50%" }}>
                    <a onClick={() => changeChain()} className="c-pointer">
                      <i className="bi bi-arrow-left-right dark-text text-black"></i>
                    </a>
                  </div>
                  {trBNB === false ? (
                    <div className="d-flex flex-row align-items-center my-auto">
                      <span>
                        <img
                          src="assets/images/icon/bscchain.svg"
                          height="30px"
                          width="30px"
                          className="rounded-pill"
                          alt=""
                        />
                      </span>
                      <div className="ms-2 text-grey-500 text-start">
                        <h5 className="text-gray-900 dark-text font-xss rajdhani-600 lh-18 text-start m-0">
                          To
                        </h5>
                        <h6 className="text-gray-900 lh-18 mb-0 dark-text">BSC</h6>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-row align-items-center my-auto">
                      <span>
                        <img
                          src="assets/images/icon/aarma.svg"
                          height="30px"
                          width="30px"
                          className="rounded-pill"
                          alt=""
                        />
                      </span>
                      <div className="ms-2 text-grey-500 text-start">
                        <h5 className="text-gray-900 dark-text font-xss rajdhani-600 lh-18 text-start m-0">
                          To
                        </h5>
                        <h6 className="text-gray-900 lh-18 mb-0 dark-text">AARMA</h6>
                      </div>
                    </div>
                  )}
                </div>
                <div className="border-exlight border border-2 mt-2 p-3 rounded-25 swap-eth">
                  <div className="d-flex justify-content-between">
                    <h6 className="dark-text">You Send</h6>
                    <h6 className="dark-text">Balance: {balance} {!isBsc ? token_bsc[4] : token_chain2[4]}</h6>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <input
                      type="text"
                      style={{ border: "none", background: "none" }}
                      onChange={(e) => setamount(e.target.value)}
                      value={amount}
                      className="dark-card w-50 dark-text rounded-10 form-control-xl-placeholder placeholder-text-gray-800 font-lg rajdhani-700 ls-0"
                      placeholder={0}
                    />
                    <div className="d-flex ">
                      <button
                        className="btn py-2 eth-button px-2"
                        onClick={() => { setIsOpenToken(!isOpenToken); setselectToken(true); }}
                      >
                        {/* {!isBsc ? token_bsc[1] : token_chain2[1]
                          ?
                          <>
                            <img
                              src={`assets/images/icon/${!isBsc ? token_bsc[1] : token_chain2[1]}`}
                              height="25px"
                              width="25px"
                              className="rounded-pill me-2"
                              alt=""
                            />
                            {!isBsc ? token_bsc[4] : token_chain2[4]}
                          </> : "Select Token"
                        } */}
                        {
                          (!isBsc ? token_bsc[1] : token_chain2[1]) ? (
                            <>
                              <img
                                src={`assets/images/icon/${!isBsc ? token_bsc[1] : token_chain2[1]}`}
                                height="25px"
                                width="25px"
                                className="rounded-pill me-2"
                                alt=""
                              />
                              {!isBsc ? token_bsc[4] : token_chain2[4]}
                            </>
                          ) : "Select Token"
                        }

                      </button>
                    </div>
                  </div>
                </div>
                <div className="border-exlight border border-2 mt-2 p-3 rounded-25 swap-eth">
                  <div className="d-flex justify-content-between">
                    <h6 className="dark-text">You receive</h6>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <input
                      type="text"
                      style={{ border: "none", background: "none" }}
                      onChange={(e) => setamount(e.target.value)}
                      value={amount}
                      className="dark-card w-50 dark-text rounded-10 form-control-xl-placeholder placeholder-text-gray-800 font-lg rajdhani-700 ls-0"
                      placeholder={0}
                    />
                    <div className="d-flex ">
                      <button
                        className="btn py-2 eth-button px-2"
                        onClick={() => { setIsOpenToken(!isOpenToken); setselectToken(false); }}
                      >
                        {/* {isBsc ? token_bsc[1] : token_chain2[1]
                          ?
                          <>
                            <img
                              src={`assets/images/icon/${isBsc ? token_bsc[1] : token_chain2[1]}`}
                              height="25px"
                              width="25px"
                              className="rounded-pill me-2"
                              alt=""
                            />
                            {isBsc ? token_bsc[4] : token_chain2[4]}
                          </>
                          : "Select Token"
                        } */}
                        {
                          (isBsc ? token_bsc[1] : token_chain2[1]) ? (
                            <>
                              <img
                                src={`assets/images/icon/${isBsc ? token_bsc[1] : token_chain2[1]}`}
                                height="25px"
                                width="25px"
                                className="rounded-pill me-2"
                                alt=""
                              />
                              {isBsc ? token_bsc[4] : token_chain2[4]}
                            </>
                          ) : "Select Token"
                        }

                      </button>
                    </div>
                  </div>
                </div>
                {hash ? (
                  <div className="p-3 text-center">
                    Hash :{" "}
                    <a
                      href={`${trBNB ? process.env.REACT_APP_EXPLORER : process.env.REACT_APP_EXPLORER_CHAIN2}tx/${hash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      #: {formatAddress(hash)}
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {account ? (
                  <>
                    {trdone ? (
                      <div className="text-center">
                        <img
                          className="border-0"
                          src="/assets/images/loader.gif"
                          alt="loading"
                          height={100}
                          width={100}
                        />
                      </div>
                    ) : (
                      <button
                        className="btn bg-current w-100 mt-3 rounded-25 py-2"
                        style={{ fontSize: "20px" }}
                        onClick={() => swap()}
                      >
                        Swap Now
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="btn bg-current w-100 mt-3 rounded-25 py-2"
                    style={{ fontSize: "20px" }}
                    onClick={() => connect()}
                  >
                    Connect
                  </button>
                )}
              </form>
              <SelectTokenModal />
              <WithdrawAdresssModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
