import React, { useContext, useEffect, useState } from 'react'
import { ProjectContext } from '../../ProjectContext';
import axios from "axios";
import Pagination from '../Coman/Pagination';

export default function Transactions({ rload }) {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "transaction",
        submethod: "getbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage, rload]);


  return (
    <div>
      <div className="contact-wrapper">
        <div className="container">
          <div className="card bg-transparent border-0  px-0">
            <div className="card-body p-0 ">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <h5 className='text-dark'><span>Transaction History</span></h5>
                    <button className='btn btn-sm bg-current rounded-25' onClick={() => getData()}><i className='bi bi-arrow-clockwise dark-text text-black'></i></button>
                  </div>
                  <div className="table-responsive mt-4">
                    <table className="table table-borderless mb-0">
                      <thead className="thead-light bg-gray-800 text-gray-900 dark-text-black ovh">
                        <tr>
                          <th scope="col" />
                          <th scope="col"><span className="rajdhani-600 lh-24 font-xs m-0 text-white">Sent Hash</span></th>
                          <th scope="col"><span className="rajdhani-600 lh-24 font-xs m-0 text-white">Receive Hash</span></th>
                          <th scope="col"><span className="rajdhani-600 lh-24 font-xs m-0 text-white">Sent Amount</span></th>
                          <th scope="col"><span className="rajdhani-600 lh-24 font-xs m-0 text-white">Receive Amount</span></th>
                          <th scope="col"><span className="rajdhani-600 lh-24 font-xs m-0 text-white">Status</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center ">
                          {isLoading ? "Data is loading" : ""}
                        </tr>
                        {!isLoading ? (
                          !data.length ? (
                            <tr className="text-center">
                              <td className="text-center" colSpan={8}>
                                <span className="w-100">No data found</span>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {data.map((ele, i) => {
                          return (
                            <tr>
                              <th scope="row" className="dark-text rajdhani-600">{ele.id}</th>
                              <td>
                                <a
                                  href={`${ele.type === 0 ? process.env.REACT_APP_EXPLORER : process.env.REACT_APP_EXPLORER_CHAIN2}tx/${ele.hash}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  # {formatAddress(ele.hash)}
                                </a>
                                <span type='button' onClick={() => copyaddress(ele.hash)}>
                                  <i class="bi bi-clipboard dark-text text-black"></i>
                                </span>
                              </td>
                              <td>
                                <a
                                  href={`${ele.type === 1 ? process.env.REACT_APP_EXPLORER : process.env.REACT_APP_EXPLORER_CHAIN2}tx/${ele.hash_sent}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  # {formatAddress(ele.hash_sent)}
                                </a>
                                <span type='button' onClick={() => copyaddress(ele.hash_sent)}>
                                  <i class="bi bi-clipboard dark-text text-black"></i>
                                </span>
                              </td>
                              {/* <td><h6 className="rajdhani-500 dark-text mb-0">{ele.amount} {ele.from_token} {ele.type === 0 ? "USDT" : "AUSDT"}</h6></td>
                              <td><h6 className="rajdhani-500 dark-text mb-0">{ele.amount} {ele.from_token} {ele.type === 1 ? "USDT" : "AUSDT"}</h6></td> */}
                              <td><h6 className="rajdhani-500 dark-text mb-0">{ele.amount} {ele.from_token} </h6></td>
                              <td><h6 className="rajdhani-500 dark-text mb-0">{ele.amount} {ele.to_token} </h6></td>
                              <td><h6 className="rajdhani-500 dark-text mb-0">
                                {ele.status === 1 ?
                                  <img src="assets/images/icon/loading.gif" alt="" srcset="" width={50} /> : ''}
                                {ele.status === 0 ? "Transaction Found" : ele.status === 1 ? "Transaction Processing" : ele.status === 2 ? "Transaction Done" : "Not found"}
                                {ele.status === 2 ?
                                  <i class="bi bi-check-lg dark-text text-black"></i> : ''}

                              </h6></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
