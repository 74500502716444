import React from "react";

export default function AdressHederModel() {
  return (
    <div>
      <div
        className="modal fade "
        id="adesssmodal"
        aria-hidden="true"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content rounded-15 ">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-top border-bottom p-3 d-flex flex-column align-items-center justify-content-center">
              <figure className="mb-3">
                <img src="assets/images/icon-14.png" alt="icon" />
              </figure>
              <h5 className="px-md-4 text-center rajdhani-600 lh-22 text-gray-900 dark-text">
                000...0000
              </h5>
            </div>
            <div className="modal-footer d-flex">
              <div className="flex-grow-1 mb-2">
                <button
                  type="button"
                  id="connectButton"
                  className="btn btn-block btn-lg bg-current text-uppercase rajdhani-600 lh-28 py-2 text-gray-900 posr"
                >
                  Copy Address
                </button>
              </div>
              <div className="flex-grow-1 mb-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-lg text-uppercase rajdhani-600 lh-28 py-2 "
                  data-bs-dismiss="modal"
                >
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
