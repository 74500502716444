import React, { useContext, useState } from 'react'
import Header from '../Coman/Header'
import BaseTakan from './BaseTakan'
import { ProjectContext } from '../../ProjectContext';
import FooterDot from '../Coman/FooterDot';
import Transactions from './Transactions';

export default function Base() {
  const [rload, setrload] = useState(true);


  return (
    <div>
      <div className="main-wrapper bg-new-body vh-11">
        <Header />
        <BaseTakan
          rload={rload}
          setrload={setrload} />
        <Transactions rload={rload} />
        <FooterDot />

      </div>
    </div>
  )
}
