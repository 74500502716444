import { debounce } from 'lodash';
import React, { useContext, useState, useEffect, useCallback } from "react";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";

export default function SelectTokenModal() {
  const { isOpenToken, setIsOpenToken, settoken_bsc, settoken_chain2, selectToken, isBsc } = useContext(ProjectContext);
  const [data, setData] = useState([]); // Initialize data as an empty array
  const [loading, setLoading] = useState(false); // Set initial loading state to false
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  
  const chainId = isBsc
    ? (selectToken ? 56 : 2889)  // If isBsc is true, use 56 if selectToken is true, otherwise 2889
    : (selectToken ? 2889 : 56);  // If isBsc is false, use 2889 if selectToken is true, otherwise 56

  // console.log("selectToken", selectToken, chainId);

  // Fetch data based on the current search term
  const getData = async () => {
    try {
      setLoading(true); // Start loading when fetching data
      const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "tokens",
        submethod: "get",
        chain: chainId,  // Use the dynamically set chain ID
        search: search,   // Use the search term in the request
        key: process.env.REACT_APP_KEY,
      });

      if (res.data.error) {
        setError("Error fetching data");
        return;
      }

      setData(res.data.data); // Set the data fetched from the API
    } catch (err) {
      console.error("Error:", err);
      setError("Error fetching data");
    } finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Debounced function to fetch data based on search input
  const debouncedGetData = useCallback(
    debounce(() => {
      getData();
    }, 500), [search, chainId] // Trigger when search or chainId changes
  );

  // Effect to trigger fetching data whenever the search or chainId changes
  useEffect(() => {
    if (isOpenToken) {
      debouncedGetData(); // Call the debounced function when the search term or chainId changes
    }
    return () => {
      debouncedGetData.cancel(); // Cleanup the debounce when the component unmounts
    };
  }, [search, isOpenToken, debouncedGetData]);

  return (
    <div>
      <div className={isOpenToken ? "modal fade show d-block" : "modal fade"}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content rounded-15 ">
            <div className="modal-header">
              <h6 className="text-gray-900 rajdhani-600 dark-text">Select a token</h6>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsOpenToken(false)} // Close modal
              />
            </div>
            <div className="modal-body border-top p-3">
              <div className="d-none d-lg-block">
                <div className="header-search bg-white border rounded-pill mt-1">
                  <i className="feather-search text-gray-500" />
                  <input
                    type="text"
                    value={search}
                    onChange={handleSearchChange} // Update search state
                    placeholder="Start typing to search.."
                    className="form-control rajdhani-600 border-0 lh-32 pt-2 pb-2 ps-5 pr-3 font-sm text-gray-700 bg-white dark-bg3 rounded-pill"
                  />
                </div>
              </div>
              <hr />
              <div className="d-block align-items-center justify-content-center">
                <div className="text-start">
                  <i className="fa fa-start" /> Tokens
                </div>

                {/* Show loading state inside the modal */}
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  // Display tokens when data is available
                  data && data.length > 0 ? (
                    data.map((e, i) => (
                      <div className="d-flex align-items-center m-2" key={i} onClick={() => {
                        // Select token based on the current chain
                        !isBsc
                          ? (selectToken ? settoken_bsc([e.name, e.image, e.contract_address, e.contract_abi, e.ticker]) : settoken_chain2([e.name, e.image, e.contract_address, e.contract_abi, e.ticker]))
                          : (selectToken ? settoken_chain2([e.name, e.image, e.contract_address, e.contract_abi, e.ticker]) : settoken_bsc([e.name, e.image, e.contract_address, e.contract_abi, e.ticker]));

                        setIsOpenToken(false); // Close modal after selection
                      }} type="button">
                        <img
                          src={`assets/images/icon/${e.image}`}
                          height="50px"
                          width="50px"
                          className="rounded-pill me-2"
                          alt=""
                        />
                        <div>
                          <p className="text-gray-900 rajdhani-600 dark-text mb-0">{e.name}</p>
                          <small>{e.ticker}</small>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No tokens available</div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
