import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Base from "./Components/Base/Base";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/base" element={<Base />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
