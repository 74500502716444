import React from 'react'
import Header from '../Coman/Header'
import Hero from './Hero'
import FooterDot from '../Coman/FooterDot'

export default function Home() {

  return (
    <div>
      <div className="main-wrapper bg-new-body vh-100">
        <Header />
        <Hero />
        <FooterDot />
      </div>
    </div>
  )
}
