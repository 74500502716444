import React, { useContext, useState } from "react";
import { ProjectContext } from "../../ProjectContext";

export default function WithdrawAdresssModal() {
  const { isOpen, setIsOpen } = useContext(ProjectContext);

  return (
    <div>
      <div>
        <di className={isOpen ? "modal fade show d-block" : "modal fade"}>
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content rounded-15 ">
              <div className="modal-header">
                <h6 className="text-gray-900 rajdhani-600 dark-text">
                  Withdraw destination
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div className="modal-body border-top border-bottom p-3 d-flex flex-column align-items-center justify-content-center">
                <input
                  type="text"
                  class=" dark-card  w-100 dark-text bg-new-body rounded-10  placeholder-text-gray-800 rajdhani-500 "
                  style={{ borderBottom: "none", border: "none" }}
                  value={"  0xbcc52823B4aC26F67FBb9286fF972E1EdfADD0c4  "}
                />{" "}
                <div className="text-start">
                  <p className="text-start mt-3 rajdhani-600 lh-22 text-gray-900 text-black bg-success rounded-20 px-3 mb-0">
                    <i class="bi bi-check-circle-fill"></i> This is your
                    Ethereum address
                  </p>
                </div>
              </div>
              <div className="modal-footer d-flex">
                <button
                  type="button"
                  id="connectButton"
                  className="btn btn-block btn-lg bg-current text-uppercase rajdhani-600 rounded-25 lh-28 py-2 text-gray-900 posr"
                >
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </di>
      </div>
    </div>
  );
}
