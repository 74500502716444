import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useSwitchNetwork,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";

import { useWeb3Modal } from "@web3modal/ethers5/react";
import { ethers } from "ethers";

export const ProjectContext = createContext();
const projectId = "add9330b54e1bff01976b4fa3fa25808";
// const projectId = process.env.REACT_APP_PROJECT_ID;
const mainnet = [//first network should be BSC scan
  {
    chainId: 56,
    name: "BSC Mainnet",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-dataseed.binance.org",
  },
  {
    chainId: 2889,
    name: "Aarma Mainnet",
    currency: "ARMA",
    explorerUrl: "https://aarmascan.com",
    rpcUrl: "https://aarmarpc.com",
  }
];

const metadata = {
  name: "Aarma Bridge",
  description: "Aarma Bridge",
  url: process.env.REACT_APP_LINK, // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: mainnet[0].chainId,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...", // used for the Coinbase SDK
  }),
  chains: mainnet,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function Projectcontext() {
  var { address, chainId, isConnected } = useWeb3ModalAccount();
  // address = "0xd00BeA2e0c6e1E71B07383AF1E8C1618cedd9f97";
  // console.log("address contexct", address);
  const { disconnect } = useDisconnect()
  const { switchNetwork } = useSwitchNetwork()
  // const { error } = useWeb3ModalError()
  // const token_bsc = "USDT"
  // const token_chain2 = "AUSDT"
  const [token_bsc, settoken_bsc] = useState([]);
  const [token_chain2, settoken_chain2] = useState([]);
  const [image, setimage] = useState("");
  // const token_chain2 = "AUSDT"
  const [selectToken, setselectToken] = useState(false);
  // console.log("token_bsc", selectToken);
  const [maticbalance, setmaticBalance] = useState(0);
  const [balance, setBalance] = useState(0);
  const [settings, setsettings] = useState([]);
  const [c_pool_data, setc_pool_data] = useState(null);
  const [rate, setrate] = useState(0);
  const [esigner, setesigner] = useState(null);
  const [dashD, setdashD] = useState(null);
  const [tokenrate, settokenrate] = useState(1);
  const [dbbalance, setdbbalance] = useState(0);
  const [dbuser, setdbuser] = useState(null);
  const [account, setaccount] = useState(address);
  const [dbuserload, setdbuserload] = useState(false);
  const [stakcontract, setstakcontract] = useState(null);
  const [stakcontractaddress, setstakcontractaddress] = useState(null);
  const [tokencontract, settokencontract] = useState(null);
  const [tokenhex, settokenhex] = useState(null);
  const [reloadData, setreloadData] = useState(false);
  const [isBsc, setisBsc] = useState(true);
  const [tokenpair, settokenpair] = useState("");
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  const [isClassToggled, setIsClassToggled] = useState(false);
  const [showmenu, setshowmenu] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenToken, setIsOpenToken] = useState(false);

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  function reload() {
    setreloadData(!reloadData)
  }
  const connectInjected = async () => {
    try {
      await open();
    } catch (ex) {
      console.log(ex);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };

  async function disconnectNow() {
    try {
      // deactivate();
      disconnect()
      localStorage.setItem("isconnected", false);
      setdbuser(null)
      setdbuserload(false)
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const connectMetamask = async () => {
    try {
      open();

      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };
  function formatDateTime(timestamp) {
    if (timestamp === 0) {
      return '-'
    }
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Get the date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Get the time components
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }
  useEffect(() => {
    // if (localStorage.getItem("isconnected") === "true") {
    //   connectMetamask();
    // }
    getSetting();
  }, []);

  // const getSigner = async () => {
  //   if (!isConnected) return;
  //   setesigner(signer)
  //   const balance = await provider.getBalance(account);
  //   setmaticBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

  // }

  const getSigner = async () => {
    if (!isConnected) return;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setesigner(signer)
    const balance = await ethersProvider.getBalance(account);
    setmaticBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

  }
  useEffect(() => {
    getSigner();
  }, [isConnected, account, walletProvider, chainId]);
  // useEffect(() => {
  //   if (isConnected && account && provider && signer) {
  //     getSigner();
  //   }
  // }, [isConnected, account, provider, signer]);
  // const getsmcs = async () => {

  //   if (!isConnected || !settings.setting || esigner === null) return;
  //   var contract = null
  //   var contract_address = null
  //   var maincontractwrite = null
  //   var hexnum = '0'
  //   console.log("mainnet[1].chainId === chainId", mainnet[0].chainId, chainId);

  //   // if (mainnet[1].chainId === chainId) {
  //   //   contract = new ethers.Contract(
  //   //     settings.contract_chain2_token,
  //   //     JSON.parse(settings.contract_chain2_token_abi),
  //   //     esigner
  //   //   );
  //   //   maincontractwrite = new ethers.Contract(
  //   //     settings.contract_chain2,
  //   //     JSON.parse(settings.contract_chain2_abi),
  //   //     esigner
  //   //   );
  //   //   contract_address = settings.contract_chain2
  //   //   hexnum = '1'
  //   // } else {
  //   //   contract = new ethers.Contract(
  //   //     settings.contract_bsc_token,
  //   //     JSON.parse(settings.contract_bsc_token_abi),
  //   //     esigner
  //   //   );
  //   //   maincontractwrite = new ethers.Contract(
  //   //     settings.contract_bsc,
  //   //     JSON.parse(settings.contract_bsc_abi),
  //   //     esigner
  //   //   );
  //   //   contract_address = settings.contract_bsc
  //   //   hexnum = '1'
  //   // }

  //   if (mainnet[1].chainId === chainId) {
  //     contract = new ethers.Contract(
  //       token_bsc[2],
  //       JSON.parse(token_bsc[3]),
  //       esigner
  //     );
  //     maincontractwrite = new ethers.Contract(
  //       settings.contract_chain2,
  //       JSON.parse(settings.contract_chain2_abi),
  //       esigner
  //     );
  //     contract_address = settings.contract_chain2
  //     hexnum = '1'
  //   } else {
  //     contract = new ethers.Contract(
  //       token_chain2[2],
  //       JSON.parse(token_chain2[3]),
  //       esigner
  //     );
  //     maincontractwrite = new ethers.Contract(
  //       settings.contract_bsc,
  //       JSON.parse(settings.contract_bsc_abi),
  //       esigner
  //     );
  //     contract_address = settings.contract_bsc
  //     hexnum = '1'
  //   }
  //   console.log("chain change", contract_address);

  //   setstakcontractaddress(contract_address)
  //   setstakcontract(maincontractwrite)
  //   settokencontract(contract)
  //   console.log("contract", contract);
  //   var balance = await contract.balanceOf(account);
  //   console.log("balance", Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

  //   setBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));
  //   var tokenHex = await maincontractwrite.allpairs(hexnum);
  //   settokenhex(tokenHex)
  // };
  // useEffect(() => {
  //   getsmcs();
  //   console.log("mainnet[1].chainId === chainId", mainnet[1].chainId, chainId);
  // }, [isConnected, account, walletProvider, settings, chainId, esigner, reloadData, token_bsc[2]], token_bsc[3], token_chain2[2], token_chain2[3]);

  const getsmcs = async () => {
    if (!isConnected || !settings.setting || esigner === null) return;

    let contract = null;
    let contract_address = null;
    let maincontractwrite = null;
    let hexnum = '0';

    // console.log("mainnet[1].chainId === chainId", mainnet[1].chainId, chainId);
    // console.log("token_bsc[2]", token_chain2[2], token_bsc[2]);
    // Dynamically select the token contract and ABI based on the chainId

    if (mainnet[1].chainId === chainId) {
      if (token_bsc[2] && token_bsc[2] !== "undefined") {
        contract = new ethers.Contract(
          token_bsc[2], // Address of the BSC token contract
          JSON.parse(token_bsc[3]), // ABI for the BSC token contract
          esigner
        );
      } else {
        console.error("Error: Invalid ABI for BSC token contract.");
        return;
      }
      if (token_bsc[2] === "0xFBAc79F584472efB65a7df0EB1efd6b42887Ff04") {
        maincontractwrite = new ethers.Contract(
          settings.contract_chain2, // Main contract address for the selected chain
          JSON.parse(settings.contract_chain2_abi), // Main contract ABI for the selected chain
          esigner
        );
        contract_address = settings.contract_chain2; // Set contract address for the chain
        hexnum = '2'; // Set specific parameter for this chain
      } else {
        maincontractwrite = new ethers.Contract(
          settings.contract_chain2, // Main contract address for the selected chain
          JSON.parse(settings.contract_chain2_abi), // Main contract ABI for the selected chain
          esigner
        );
        contract_address = settings.contract_chain2; // Set contract address for the chain
        hexnum = '1'; // Set specific parameter for this chain
      }

    } else {
      if (token_chain2[2] && token_chain2[2] !== "undefined") {
        contract = new ethers.Contract(
          token_chain2[2], // Address of Chain 2 token contract
          JSON.parse(token_chain2[3]), // ABI for Chain 2 token contract
          esigner
        );
      } else {
        console.error("Error: Invalid ABI for Chain 2 token contract.");
        return;
      }
      if (token_chain2[2] === "0xFBAc79F584472efB65a7df0EB1efd6b42887Ff04") {
        maincontractwrite = new ethers.Contract(
          settings.contract_bsc, // Main contract address for BSC
          JSON.parse(settings.contract_bsc_abi), // Main contract ABI for BSC
          esigner
        );
        contract_address = settings.contract_bsc; // Set contract address for BSC
        hexnum = '2'; // Set specific parameter for BSC
      } else {
        maincontractwrite = new ethers.Contract(
          settings.contract_bsc, // Main contract address for BSC
          JSON.parse(settings.contract_bsc_abi), // Main contract ABI for BSC
          esigner
        );
        contract_address = settings.contract_bsc; // Set contract address for BSC
        hexnum = '1'; // Set specific parameter for BSC
      }
    }


    // console.log("token_bsc[2] , token_chain2[2] ", token_bsc[2], token_chain2[2]);

    if (token_chain2[2] === "0xf1F0FA0287C47804636fFeF14e2C241f2587903e" && token_bsc[2] === "0xFBAc79F584472efB65a7df0EB1efd6b42887Ff04") {
      hexnum = '2';
    }
    // console.log("Selected contract address:", contract_address, hexnum);
    // Update states for contract and address
    setstakcontractaddress(contract_address);
    setstakcontract(maincontractwrite);
    settokencontract(contract);

    try {
      // Check if the contract has balanceOf method
      if (contract.balanceOf) {
        const balance = await contract.balanceOf(account);
        const formattedBalance = Number(ethers.utils.formatUnits(balance, 18)).toFixed(2);
        // console.log("Balance:", formattedBalance);

        // Update the balance state
        setBalance(formattedBalance);
      } else {
        console.error("Error: Contract does not have 'balanceOf' method.");
      }

      // Fetch token pairs from the main contract
      const tokenHex = await maincontractwrite.allpairs(hexnum);
      const tokenPair = await maincontractwrite.tokenpairs(tokenHex);
      settokenpair(tokenPair);
      settokenhex(tokenHex);  // Update token pairs state
    } catch (error) {
      console.error("Error fetching token balance or pairs:", error);
    }
  };



  // Set up useEffect to trigger on necessary dependencies
  useEffect(() => {
    getsmcs();
    // console.log("mainnet[1].chainId === chainId", mainnet[1].chainId, chainId);
  }, [
    isConnected,
    account,
    walletProvider,
    settings,
    chainId,
    esigner,
    reloadData,
    token_bsc[2],
    token_bsc[3],
    token_chain2[2],
    token_chain2[3]
  ]);



  function checkNegative(value) {
    if (value < 0) {
      return 0;
    } else {
      return value;
    }
  }
  useEffect(() => {
    setaccount(address);
    // if (address) {
    //   localStorage.setItem("isconnected", true);
    // }
  }, [address]);
  useEffect(() => {
    console.log("chainId", chainId);
    if (chainId === mainnet[0].chainId) {
      setisBsc(true)
    } else {
      setisBsc(false)
    }
  }, [chainId])

  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <ProjectContext.Provider
        value={{
          settings: settings,
          library: ethers,
          balance,
          maticbalance,
          dbuser, dashD,
          esigner,
          tokenrate,
          account: account ? account.toLowerCase() : account,
          connectInjected,
          disconnectNow, checkNegative,
          connectMetamask,
          formatAddress,
          toastError,
          toastSuccess, c_pool_data,
          getudata, dbbalance, dbuserload,
          toastInfo,
          getsmcs,
          ethers,
          rate,
          copyaddress,
          stakcontract, tokencontract, stakcontractaddress,
          formatDateTime, setaccount, open,
          reload, reloadData, switchNetwork,
          isClassToggled, setIsClassToggled, showmenu, setshowmenu,
          isBsc, token_chain2, settoken_chain2, token_bsc, settoken_bsc, tokenhex, isOpen, setIsOpen, isOpenToken, setIsOpenToken, selectToken, setselectToken, image, setimage, tokenpair,
          formatAddress
        }}
      >
        <App />
      </ProjectContext.Provider >
    </>
  );
}

export default Projectcontext;
